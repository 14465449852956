<template>
  <div v-if="productAttribute" :class="[productAttribute.class, 'product-attribute']">
    {{ productAttribute.text }}
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProductTileLabel',
  props: {
    product: {
      type: Object,
      default: () => {}
    },
    isProductSold: {
      type: Boolean,
      default: false
    },
    minPrice: {
      type: Object,
      default: () => {}
    },
    infoLabel: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      attributesByCode: 'attribute/attributeListByCode'
    }),
    productAttribute () {
      if (this.isProductSold) return { class: 'sold', text: this.$t('Sold') }
      if (this.product.info_label && !!Number(this.minPrice.final_price)) return { class: 'bg-cl-special-offer', text: this.infoLabel }
      if (this.product.news) return { class: 'new', text: this.$t('New in') }
      if (this.product.new_with_tag) return { class: 'new-with-tag', text: this.$t('Brand new') }
      if (this.product.bestseller) return { class: 'bestseller', text: this.$t('Bestseller') }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.product-attribute {
  cursor: default;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 5;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  padding: 5px 10px;
  &.sold {
    background-color: #000;
  }
  &.new {
    background-color: #4BDEBB;
  }
  &.new-with-tag {
    background-color: #B595C9;
  }
  &.bestseller {
    background-color: #F3A1B5;
  }
}
</style>
